import React from "react";
import "../../styles/Loading.css";

export default function Loading({ language }) {
  let loadingContent = {
    english: {
      text: "Loading...",
    },
    german: {
      text: "Wird geladen...",
    },
  };

  language.split("-")[0] === "de"
    ? (loadingContent = loadingContent.german)
    : (loadingContent = loadingContent.english);

  return (
    <div className="Loading-Container">
      <div className="demo">
        <div className="circle">
          <div className="inner"></div>
        </div>
        <div className="circle">
          <div className="inner"></div>
        </div>
        <div className="circle">
          <div className="inner"></div>
        </div>
        <div className="circle">
          <div className="inner"></div>
        </div>
        <div className="circle">
          <div className="inner"></div>
        </div>
      </div>
      <h1>{loadingContent.text}</h1>
    </div>
  );
}
