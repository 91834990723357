import React from "react";
import Navbar from "./Nav";
import Footer from "./Footer";
import "../styles/Legal.css";

export default React.memo(function PrivacyPolicy({ language }) {
  window.scrollTo(0, 0);

  let privacyPolicyContent = {
    english: {
      title1: "Privacy Policy",
      title2: "Privacy Policy according to GDPR",
      text1:
        "We would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed of the rights to which they are entitled. Art. 4 GDPR should be read if definitions of certain terms such as ‘personal data’ are required.",
      title3: "1. Name and address of the responsible",
      text2:
        "The responsible within the meaning of the GDPR and other national data protection laws of the member states, as well as other data protection regulations is:",
      text3: "Laven Webdesign & -development",
      textX: "Nevzat Cesur",
      text4: "Am Margaretenbrünnele 19",
      text5: "86470 Thannhausen",
      text6: "Germany",
      text7: "E-Mail: laven.web@gmail.com",
      text8: "Website: www.laven-web.com",
      title4: "2. Name and address of the data protection officer",
      title5: "The data protection officer of the responsible (see above) is:",
      title6: " 3. General information on data processing",
      title7: "3.1. Scope of data processing ",
      text9:
        "Your privacy matters to us. Therefore, we only process the personal data of our users if it is necessary in order to provide a functioning website and to our content and services. The processing of personal data of our users occurs with the consent of the user. An exception applies in the cases in which prior consent can not be obtained for reasons of fact and the data processing is permitted by law.",
      title8: "3.2. Legal basis for data processing ",
      title11:
        "The processing is lawful if one of the following conditions are met:",
      text10:
        "In the case we have obtained the consent of the data subject, Art. 6 para. 1 lit. a GDPR serves as the legal basis.",
      text11:
        "If the processing of personal data is necessary in order to execute a contract, to which the data subject is a party of, then Art. 6 para. 1 lit. b GDPR serves as the legal basis. This also applies to processing operations required to carry out prior to contractual actions.",
      text12:
        "If the processing of personal data is required in order to fulfill a legal obligation that we have to obey to as a company, Art. 6 para. 1 lit. c GDPR serves as the legal basis.",
      text13:
        "If we are obligated to process personal data in order to protect the vital interests of the data subject or another natural person, then Art. 6 para. 1 lit. d GDPR applies as the legal basis. If the processing is necessary in order to perform a task, that has been assigned to us, carried out in the public interest or in the exercise of official authority, then Art. 6 para. 1 lit. e GDPR serves as the legal basis.",
      text14:
        "If processing is necessary in order to protect the legitimate interests of our company or a third party, and if the interests, fundamental rights and freedoms of the data subject do not prevail over the first mentioned interests, then Art. 6 para. 1 lit. f GDPR applies as the legal basis.",
      title12: "3.3. Data deletion and storage duration",
      text15:
        "The personal data of the data subject will be deleted or blocked once the purpose of the storage has been achieved. Storage of personal data may have to take place if it is required by the European or national legislator in EU regulations, laws or other regulations to which we are subject to. Blocking or deletion of the data also occurs when a compulsory storage period expires, unless there is a need for further storage of the data in order to conclude or execute a contract.",
      title13: "4. Google Analytics",
      text16:
        "This website uses Google Analytics, a web analysis service of Google Inc.",
      title14: "4.1. Description and scope of data processing ",
      text17:
        "Google Analytics uses “cookies” to analyse our website visitors’ behaviours. On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on website activity and to provide the website operator with data such as the total number of website visitors, their regional origin, the average session duration and bounce rate. However, we have activated IP anonymisation, which means that Google will reduce your IP address. This will result in better data protection as the IP address can give away your exact location.",
      title15: "4.2. Legal basis for data processing",
      text18:
        "The legal basis in this case is our interest in data processing of our website users since it gives us the opportunity to evaluate our website performance and further improve it. For that reason, Art. 6 para. 1 lit. f GDPR serves as the legal basis.",
      text19:
        "If the user has beforehand agreed with our privacy policy, then Art. 6 para. 1 lit. a GDPR serves as the legal basis.",
      title16: "4.3. Purpose of data processing",
      text20:
        "The use of Google Analytics enables us to analyse, optimize and improve our website. Because of the continuous improvement of our website, we are able to provide a more user-friendly website which will result in higher user satisfaction.",
      title17: "4.4. Storage Duration",
      text21:
        "The deletion of data, which have already reached their storage limit, occurs automatically.",
      title18: "4.5. Option for objection and elimination ",
      text22:
        "If you would not like Google Analytics to track your website visits, you can use this ",
      text23: ", which has been made available by Google.",
      title19: "5. Contact form and E-Mail contact",
      title20: "5.1. Description and scope of data processing",
      text24:
        "We have provided a contact form on our website which can be used by visitors for contacting us. If the users decides to contact us, the information written on the input mask will be conveyed to us. This information is the users’ name, their E-Mail address and their message to us.",
      text25:
        "Simultaneously the moment you send your message, we store the date and time.",
      text26:
        "We refer to this privacy policy during the submit process, which the user has to agree with in order to proceed.",
      text27:
        "Alternatively, the user can contact us via E-Mail with the provided E-Mail address underneath the contact form. In this case we have access to the personal data which are conveyed to us through their E-Mail client.",
      text28:
        "A transfer of your data to a third party doesn’t occur during this process. We will use the data in order to process the conversation.",
      title21: "5.2. Legal basis for data processing",
      text29:
        "With prior consent our users Art. 6 para. 1 lit. a GDPR applies as the legal basis.",
      text30:
        "The processing of data, which have been provided through E-Mail contact, is Art. 6 para. 1 lit. f GDPR.",
      text31:
        "If the E-Mail contact has the ultimate purpose of executing a contract, then additionally Art. 6 para. 1 lit. b GDPR serves as the legal basis.",
      title22: "5.3. Purpose of data processing",
      text32:
        "The processing of the data we have collected through the input mask of our contact form solely serves for communication purposes.",
      text33:
        "The other information we require on the input mask allow us to avoid any misuse of our contact form and ensure its safety.",
      title23: "5.4. Storage duration",
      text34:
        "The data will be deleted as soon as the purpose of it has been achieved. This will be the case once the conversation has been terminated.",
      title24: "5.5. Option for objection and elimination",
      text35:
        "The users always have the option to withdraw their consents. If this is the case, the conversation cannot be continued.",
      text36:
        "If the users request a withdrawal of their consents, then the data which has been collected throughout the whole process will be immediately deleted.",
      title25: "6. Rights of the person concerned",
      text37:
        "If your personal data is being processed, you are the person concerned according to the GDPR and you have the following rights against the person responsible (i.e., Laven Webdesign & -development):",
      title26: "6.1. Right to information",
      text38:
        "You have the right ask if personal data of you is being processed by us. If such processing occurs, you have the right to request information about your personal data from the responsible.",
      title27: "6.2. Right to rectification",
      text39:
        "If the personal data concerning you is incorrect or incomplete, you have the right to rectification.",
      title28: "6.3. Right to restriction, deletion and objection",
      text40:
        "If certain legal requirements are present, you have the right to request a deletion or restriction of the processing of your personal data and you also have the right to object to the processing of your personal data.",
      title29: "6.4. Right to information",
      text41:
        "If you have the right to rectification, deletion or restriction of data processing, the responsible is obliged to notify all recipients to whom your personal data have been disclosed of this correction or deletion of the data or restriction of processing, unless this proves to be impossible or involves a disproportionate amount of effort. You have the right to be informed about these recipients by the responsible.",
      title30: "6.5. Right to Data Portability",
      text42:
        "You have the right to receive your personal data, you have provided to the responsible, in a structured, common and machine-readable format.",
      title31: "6.6. Right to revoke the privacy policy consent",
      text43:
        "You have the right to revoke your consent at any time. The revocation does not affect the legality of the processing carried out on the basis of the prior consent until the request of the revocation.",
      title32: "6.7. Right to complain",
      text44:
        "You have the right to complain to a supervisory authority, in particular in the Member State of your residence, if you believe that the processing of your personal data violates the GDPR.",
    },
    german: {
      title1: "Datenschutzerklärung",
      title2: "Datenschutzerklärung nach DSGVO",
      text1:
        "Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts über die Art, den Umfang und Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Bezüglich der Definition von Begriffen wie etwa „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf Art. 4 DSGVO.",
      title3: "1. Name und Anschrift des Verantwortlichen",
      text2:
        "Der Verantwortliche der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:",
      text3: "Laven Webdesign & -entwicklung",
      textX: "Nevzat Cesur",
      text4: "Am Margaretenbrünnele 19",
      text5: "86470 Thannhausen",
      text6: "Deutschland",
      text7: "E-Mail: laven.web@gmail.com",
      text8: "Website: www.laven-web.com",
      title4: "2. Name und Anschrift des Datenschutzbeauftragten",
      title5:
        " Der Datenschutzbeauftragte des Verantwortlichen (siehe oben) ist:",
      title6: " 3. Allgemeines zur Datenverarbeitung",
      title7: "3.1. Umfang der Verarbeitung personenbezogener Daten",
      text9:
        "Ihre Privatsphäre ist uns wichtig. Daher verarbeiten wir personenbezogene Daten unserer Nutzer nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.",
      title8:
        "3.2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten",
      title11:
        "Die Verarbeitung ist rechtmäßig, wenn mindestens eine der nachstehenden Bedingungen erfüllt ist:",
      text10:
        "Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. (lat. littera = Buchstabe) a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.",
      text11:
        "Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.",
      text12:
        "Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, gegenüber den wir als Verantwortliche unterliegen, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.",
      text13:
        " Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, um diese zu schützen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. Falls die Verarbeitung für die Wahrnehmung unserer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, erforderlich ist, dient Art. 6 Abs. 1 lit. e DSGVO als Rechtsgrundlage.",
      text14:
        "Falls die Verarbeitung zur Wahrung der berechtigten Interessen unseres Unternehmens oder eines Dritten erforderlich ist, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage.",
      title12: "3.3. Datenlöschung und Speicherdauer",
      text15:
        "Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.",
      title13: "4. Google Analytics",
      text16:
        "Wir benutzen das Websitenanalyse-Tool „Google Analytics“ (Google Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland).",
      title14: "4.1. Beschreibung und Umfang der Datenverarbeitung",
      text17:
        "Beim Besuch unserer Website setzt Google Analytics Cookies auf Ihren Rechner, um Ihr Verhalten auf unserer Website zu analysieren. Daher können wir Daten wie die Besucheranzahl, die regionale Herkunft der Besucher, die Besucherquellen, die Verweildauer sowie die durchschnittliche Anzahl der aufgerufenen Seiten pro Besuch erhalten und anschließlich auswerten. Jedoch haben wir die IP-Anonymisierung aktiviert, wodurch die IP-Adressen unserer Besucher gekürzt wird. Ein Nutzer kann anhand der IP-Adresse eindeutig lokalisiert und zugeordnet werden, daher umfasst es auch personenbezogene Daten. Wegen der Anonymisierung können somit keine personenbezogenen Daten beim Besuch unserer Website an Google übertragen werden.",
      title15: "4.2. Rechtsgrundlage für die Datenverarbeitung",
      text18:
        " Rechtsgrundlage hierfür ist unsere Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO, da Google Ihre Nutzung der Website auswertet, um uns wichtige Kennzahlen über die Websitenaktivitäten zu liefern.",
      text19:
        "Wird diese Datenschutzerklärung von dem Nutzer eingewilligt, so liegt als Rechtsgrundlage für die Verarbeitung der Daten Art. 6 Abs. 1 lit. a DSGVO vor.",
      title16: "4.3. Zweck der Datenverarbeitung",
      text20:
        "Die Nutzung von Google Analytics dient dem Zweck der Analyse, Optimierung und Verbesserung unserer Website. Durch die stetige Optimierung von unserer Website, steigt auch somit die Nutzerfreundlichkeit, wovon die User profitieren können.",
      title17: "4.4. Dauer der Speicherung",
      text21:
        "Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch.",
      title18: "4.5. Widerspruchs- und Beseitigungsmöglichkeit",
      text22:
        "Falls Sie nicht wollen, dass Ihre Besuche von Google Analytics getrackt werden, dann können Sie diesen",
      text23: ", der von Google bereitgestellt wurde, nutzen.",
      title19: "5. Kontaktformular und E-Mail-Kontakt",
      title20: "5.1. Beschreibung und Umfang der Datenverarbeitung",
      text24:
        "Auf unserer Internetseite ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegebenen Daten an uns übermittelt und gespeichert. Diese Daten sind Ihr Vorname, Ihre E-Mail Adresse, Ihre Nachricht an uns und falls Sie angegeben haben, Ihr Nachname.",
      text25:
        "Gleichzeitig wird im Zeitpunkt der Absendung Ihrer Nachricht das Datum und die Uhrzeit gespeichert.",
      text26:
        "Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.",
      text27:
        "Alternativ ist eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.",
      text28:
        "Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.",
      title21: "5.2. Rechtsgrundlage für die Datenverarbeitung",
      text29:
        "Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.",
      text30:
        "Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1 lit. f DSGVO.",
      text31:
        "Zielt der E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist die zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.",
      title22: "5.3. Zweck der Datenverarbeitung",
      text32:
        "Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten.",
      text33:
        "Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.",
      title23: "5.4. Dauer der Speicherung",
      text34:
        "Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.",
      title24: "5.5. Widerspruchs- und Beseitigungsmöglichkeit",
      text35:
        "Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.",
      text36:
        "Falls Sie Ihre Einwilligung zur Verarbeitung Ihrer Daten widerrufen möchten, werden alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, gelöscht.",
      title25: "6. Rechte der betroffenen Person",
      text37:
        "Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener im Sinne der DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu. Wenn Sie darüber informiert werden möchten, welche personenbezogene Daten wir über Sie haben oder wenn Sie möchten, dass diese aus unserem System gelöscht werden, dann kontaktieren Sie uns bitte.",
      title26: "6.1. Auskunftsrecht",
      text38:
        "Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie das Recht Auskunft über Ihre Daten zu erhalten.",
      title27: "6.2. Recht auf Berichtigung",
      text39:
        "Falls wir unrichtige oder unvollständige Information von Ihnen haben, steht Ihnen das Recht auf Berichtigung dieser Information zu.",
      title28:
        "6.3. Recht auf Einschränkung, Recht auf Löschung und Widerspruchsrecht",
      text40:
        "Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder Einschränkung der Verarbeitung Ihrer Daten verlangen sowie Widerspruch gegen die Verarbeitung einlegen.",
      title29: "6.4. Recht auf Unterrichtung",
      text41:
        "Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen Ihre personenbezogenen Daten weitergeleitet wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen. Es es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.",
      title30: "6.5. Recht auf Datenübertragbarkeit",
      text42:
        "Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.",
      title31: "6.6. Recht auf Widerruf der Datenschutz-Einwilligungserklärung",
      text43:
        "Sie können jederzeit Ihre datenschutzrechtliche Einwilligungserklärung widerrufen und somit Ihre davorige Einverständnis zurückziehen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. An erster Stelle haben Sie auch das Recht, die Datenschutzerklärung abzulehnen. Jedoch verhindert dies beispielsweise Ihre erste Kontaktaufnahme mit uns.",
      title32: "6.7. Beschwerderecht",
      text44:
        "Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.",
    },
  };

  language.split("-")[0] === "de"
    ? (privacyPolicyContent = privacyPolicyContent.german)
    : (privacyPolicyContent = privacyPolicyContent.english);

  return (
    <>
      <div className="Legal-Container">
        <Navbar language={language} />
        <h1>{privacyPolicyContent.title1}</h1>
        <div className="Privacy-Container">
          <div className="Privacy-Section-Container">
            <h2 className="Privacy-Title-Main">
              {privacyPolicyContent.title2}
            </h2>
            {privacyPolicyContent.text1}
          </div>

          <div className="Privacy-Section-Container">
            <h2 className="Privacy-Title-Main">
              {privacyPolicyContent.title3}
            </h2>
            <span className="Privacy-Break">{privacyPolicyContent.text2}</span>
            <br />
            {privacyPolicyContent.text3} <br /> {privacyPolicyContent.text4}
            <br /> {privacyPolicyContent.text5}
            <br /> {privacyPolicyContent.text6} <br />
            {privacyPolicyContent.text7}
            <br /> {privacyPolicyContent.text8}
          </div>

          <div className="Privacy-Section-Container">
            <h2 className="Privacy-Title-Main">
              {privacyPolicyContent.title4}
            </h2>
            <span className="Privacy-Break">{privacyPolicyContent.title5}</span>
            <br />
            {privacyPolicyContent.textX} <br /> {privacyPolicyContent.text4}
            <br /> {privacyPolicyContent.text5}
            <br /> {privacyPolicyContent.text6} <br />
            {privacyPolicyContent.text7}
            <br /> {privacyPolicyContent.text8}
          </div>
          <div className="Privacy-Section-Container Privacy-Main">
            <h2 className="Privacy-Title-Main">
              {privacyPolicyContent.title6}
            </h2>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title7}</h3>{" "}
              {privacyPolicyContent.text9}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title8}</h3>
              <span className="Privacy-Break">
                {privacyPolicyContent.title11}
              </span>
              <br />
              {privacyPolicyContent.text10}
              <br /> {privacyPolicyContent.text11}
              <br /> {privacyPolicyContent.text12}
              <br /> {privacyPolicyContent.text13}
              <br /> {privacyPolicyContent.text14}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title12}</h3>
              {privacyPolicyContent.text15}
            </div>
          </div>
          <div className="Privacy-Section-Container Privacy-Main">
            <div className="Privacy-Section-Container">
              <h2 className="Privacy-Title-Main">
                {privacyPolicyContent.title13}
              </h2>{" "}
              {privacyPolicyContent.text16}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title14}</h3>{" "}
              {privacyPolicyContent.text17}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title15}</h3>
              {privacyPolicyContent.text18}
              <br /> {privacyPolicyContent.text19}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title16}</h3>
              {privacyPolicyContent.text20}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title17}</h3>
              {privacyPolicyContent.text21}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title18}</h3>
              {privacyPolicyContent.text22}
              <a
                id="Browser-Add-on"
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
              >
                Browser-Add-on
              </a>
              {privacyPolicyContent.text23}
            </div>
          </div>

          <div className="Privacy-Section-Container Privacy-Main">
            <h2 className="Privacy-Title-Main">
              {privacyPolicyContent.title19}
            </h2>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title20}</h3>
              {privacyPolicyContent.text24}
              <br /> {privacyPolicyContent.text25}
              <br /> {privacyPolicyContent.text26}
              <br /> {privacyPolicyContent.text27}
              <br /> {privacyPolicyContent.text28}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title21}</h3>
              {privacyPolicyContent.text29}
              <br />
              {privacyPolicyContent.text30}
              <br /> {privacyPolicyContent.text31}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title22}</h3>
              {privacyPolicyContent.text32}
              <br /> {privacyPolicyContent.text33}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title23}</h3>{" "}
              {privacyPolicyContent.text34}
            </div>
            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title24}</h3>{" "}
              {privacyPolicyContent.text35}
              <br /> {privacyPolicyContent.text36}
            </div>
          </div>

          <div className="Privacy-Section-Container Privacy-Main">
            <div className="Privacy-Section-Container">
              <h2 className="Privacy-Title-Main">
                {privacyPolicyContent.title25}
              </h2>
              {privacyPolicyContent.text37}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title26}</h3>{" "}
              {privacyPolicyContent.text38}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title27}</h3>{" "}
              {privacyPolicyContent.text39}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title28}</h3>
              {privacyPolicyContent.text40}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title29}</h3>{" "}
              {privacyPolicyContent.text41}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title30}</h3>{" "}
              {privacyPolicyContent.text42}
            </div>

            <div className="Privacy-Section-Container">
              <h3>{privacyPolicyContent.title31}</h3>
              {privacyPolicyContent.text43}
            </div>

            <div className="Privacy-Section-Container">
              <h3> {privacyPolicyContent.title32}</h3>{" "}
              {privacyPolicyContent.text44}
            </div>
          </div>
        </div>
      </div>
      <Footer inverted language={language} />
    </>
  );
});
