import React, { useEffect } from "react";
import "../styles/Contact.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Contact({ language }) {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const contactAnimation = useAnimation();

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d2xvkhp",
        "customer_message",
        e.target,
        "user_fooLtHPdJjInKoaDudDNt"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  let contactContent = {
    english: {
      title: "Contact us",
      text1: "Forename",
      text2: "John",
      text3: "Surname",
      text4: "Doe",
      text5: "E-mail adress",
      text6: "John-Doe@example.com",
      text7: "Your message",
      text8: "Your message...",
      text9: "I agree to the ",
      text10: "privacy policy.",
      text11: "",
      text12: "Send",
      text13: "Or contact us directly via email at:",
      link: "/privacy_policy",
    },
    german: {
      title: "Kontaktieren Sie uns",
      text1: "Vorname",
      text2: "Max",
      text3: "Nachname",
      text4: "Mustermann",
      text5: "E-mail Adresse",
      text6: "Max-Mustermann@beispiel.de",
      text7: "Ihre Nachricht",
      text8: "Ihre Nachricht...",
      text9: "Ich willige der ",
      text10: "Datenschutzerklärung",
      text11: "ein.",
      text12: "Senden",
      text13: "Oder kontaktieren Sie uns direkt über:",
      link: "/datenschutz",
    },
  };

  language.split("-")[0] === "de"
    ? (contactContent = contactContent.german)
    : (contactContent = contactContent.english);

  useEffect(() => {
    if (inView) {
      contactAnimation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease-in-out",
          duration: 0.7,
        },
      });
    }
  }, [inView, contactAnimation]);

  return (
    <div className="Contact-Container">
      <h1 className="Contact-Title" id="contact">
        {contactContent.title}
      </h1>
      <hr />
      <motion.form
        ref={ref}
        className="Contact-Form-Container"
        onSubmit={sendEmail}
        id="customer_message"
        initial={{ y: "6rem", opacity: 0 }}
        animate={contactAnimation}
      >
        <div className="Contact-Form-Sub-Container">
          <div className="Contact-Name-Container">
            <label htmlFor="forename">{contactContent.text1}</label>
            <input
              type="text"
              id="forename"
              name="forename"
              placeholder={contactContent.text2}
              required
            />
          </div>
          <div className="Contact-Name-Container">
            <label htmlFor="surname">{contactContent.text3}</label>
            <input
              type="text"
              id="surname"
              name="surname"
              placeholder={contactContent.text4}
              required
            />
          </div>
        </div>
        <div className="Contact-Email-Container">
          <label htmlFor="email">{contactContent.text5}</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder={contactContent.text6}
            required
          />
        </div>
        <div className="Contact-Message-Container">
          <label htmlFor="message">{contactContent.text7}</label>
          <textarea
            type="text"
            id="message"
            name="message"
            placeholder={contactContent.text8}
            required
          ></textarea>
        </div>
        <div className="Contact-Tac-Container">
          <input type="checkbox" id="tac" name="tac" required />
          <label htmlFor="tac">
            {contactContent.text9}
            <span id="Privacy-Link">
              <Link to={contactContent.link}>{contactContent.text10}</Link>
            </span>{" "}
            {contactContent.text11}
          </label>
        </div>
        <div className="Contact-Submit-Container">
          <button
            type="submit"
            value={contactContent.text12}
            form="customer_message"
          >
            {contactContent.text12}
          </button>
          <div>
            <p className="Contact-Text">
              {contactContent.text13}
              <br />
              laven.web@gmail.com
            </p>
          </div>
        </div>
      </motion.form>
    </div>
  );
}
