import React, { useState, useEffect } from "react";
import "../styles/Clients.css";
import Deco from "../images/Clients-bg-deco.svg";
import DecoM from "../images/Clients-bg-deco-M.svg";
import DecoL from "../images/Clients-bg-deco-L.svg";
import { motion } from "framer-motion";

export default function Clients() {
  let [Width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let WidthChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", WidthChange);
  }, [Width]);

  const imageVariants = {
    hidden: {
      opacity: 0,
      y: "6rem",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 2.5,
        duration: 1,
      },
    },
  };

  return (
    <div className="Clients-Container">
      <motion.div
        className="Clients-Deco-Container"
        variants={imageVariants}
        initial={window.innerWidth <= 768 ? "visible" : "hidden"}
        animate={"visible"}
      >
        <img
          src={Width <= 768 ? Deco : Width <= 1020 ? DecoM : DecoL}
          alt=""
          id="Client-Deco"
        />
        <div className="Clients-Gradient" />
      </motion.div>
    </div>
  );
}
