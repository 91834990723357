import React from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";

export default function Footer(props) {
  let footerContent = {
    english: {
      pathOne: "/privacy_policy",
      titleOne: "Privacy Policy",
      pathTwo: "legal_notice",
      titleTwo: "Legal Notice",
      titleThree: "© 2021-2023 Laven Webdesign & -development",
    },
    german: {
      pathOne: "/datenschutz",
      titleOne: "Datenschutz",
      pathTwo: "/impressum",
      titleTwo: "Impressum",
      titleThree: "© 2021-2023 Laven Webdesign & -entwicklung",
    },
  };

  props.language.split("-")[0] === "de"
    ? (footerContent = footerContent.german)
    : (footerContent = footerContent.english);

  return (
    <footer>
      <div
        className="Footer-Main"
        style={
          props.inverted
            ? {
                boxShadow: "rgba(0,0,0, 0.2) 0 -5px 30px",
                borderTop: "solid 2px rgba(0,0,0, 0.1)",
                marginTop: "5rem",
              }
            : {}
        }
      >
        <div className="Footer-Main-Subs">
          <h2>
            <Link to={footerContent.pathOne}>{footerContent.titleOne}</Link>
          </h2>
          <hr />
        </div>
        <div className="Footer-Main-Subs">
          <h2>
            <Link to={footerContent.pathTwo}>{footerContent.titleTwo}</Link>
          </h2>
          <hr />
        </div>
      </div>
      <div className="Footer-Subcontainer">
        <h2>{footerContent.titleThree}</h2>
      </div>
    </footer>
  );
}
