import React from "react";
import Nav from "./Nav";
import Home from "./Home";
import Clients from "./Clients";
import Pitch from "./Pitch";
import Benefits from "./Benefits";
import Process from "./Process";
import Contact from "./Contact";
import Footer from "./Footer";

export default function PAGE({ language }) {
  return (
    <>
      <Nav animate language={language} />
      <Home language={language} />
      <Clients language={language} />
      <div className="Background">
        <Pitch language={language} />
        <Benefits language={language} />
        <Process language={language} />
        <Contact language={language} />
        <Footer language={language} />
      </div>
    </>
  );
}
