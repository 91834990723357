import React, { useState, useEffect } from "react";
import "./styles/App.css";
import PAGE from "./components/PAGE";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LegalNotice from "./components/LegalNotice";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import ReactGa from "react-ga";
import Loading from "./components/sub/Loading";

function App() {
  ReactGa.initialize("UA-208795525-1");
  ReactGa.pageview(window.location.pathname + window.location.search);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  let language = navigator.language || navigator.userLanguage;

  let appContent = {
    english: {
      pathOne: "/privacy_policy",
      pathTwo: "/legal_notice",
    },
    german: {
      pathOne: "/datenschutz",
      pathTwo: "/impressum",
    },
  };

  language.split("-")[0] === "de"
    ? (appContent = appContent.german)
    : (appContent = appContent.english);

  return (
    <>
      {loading === false ? (
        <Switch>
          <Route
            exact
            path="/"
            component={() => <PAGE language={language} />}
          />
          <Route
            exact
            path={appContent.pathOne}
            component={() => <PrivacyPolicy language={language} />}
          />
          <Route
            exact
            path={appContent.pathTwo}
            component={() => <LegalNotice language={language} />}
          />
        </Switch>
      ) : (
        <Loading language={language} />
      )}
    </>
  );
}

export default App;
