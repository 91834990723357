import React, { useEffect, useState, useRef } from "react";
import "../styles/Home.css";
import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";

export default function Home({ language }) {
  let [Scroll, setScroll] = useState(window.scrollY);
  let lastWidth = useRef(window.innerWidth);
  let [Width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let WidthChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", WidthChange);
  }, [Width]);

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    if (Width <= 768) {
      window.scroll({
        top: elementPosition,
        left: 0,
      });
    } else {
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let ChangeScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", ChangeScroll);

    return () => {
      window.removeEventListener("scroll", ChangeScroll);
    };
  }, [Scroll]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", function () {
      if (window.innerWidth !== lastWidth.current) {
        init();
        lastWidth.current = window.innerWidth;
      }
    });
  }, [lastWidth]);

  function init() {
    var style = ["style1", "style2", "style3", "style4"];
    var tam = ["tam1", "tam1", "tam1", "tam2", "tam3"];
    var opacity = [
      "opacity1",
      "opacity1",
      "opacity1",
      "opacity2",
      "opacity2",
      "opacity3",
    ];

    function getRandomArbitrary(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    }

    var stars = "";
    var sky = document.querySelector(".constellation");
    var widthWindow = window.innerWidth;
    var heightWindow = window.innerHeight;
    var qtdestarss = () => {
      if (widthWindow <= 768) {
        return 100;
      } else if (widthWindow <= 1020) {
        return 200;
      } else {
        return 250;
      }
    };

    for (var i = 0; i < qtdestarss(); i++) {
      stars +=
        "<span class='stars " +
        style[getRandomArbitrary(0, 4)] +
        " " +
        opacity[getRandomArbitrary(0, 7)] +
        " " +
        tam[getRandomArbitrary(0, 5)] +
        "' style='animation-delay: ." +
        getRandomArbitrary(0, 7) +
        "s; left: " +
        getRandomArbitrary(20, widthWindow - 20) +
        "px; top: " +
        getRandomArbitrary(0, heightWindow) +
        "px;'></span>";
    }

    sky.innerHTML = stars;
  }

  const homeVariants = {
    hidden: {
      opacity: 0,
      x: "-10rem",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        delay: 0.5,
        type: "ease-in-out",
      },
    },
  };

  let homeContent = {
    english: {
      text1: "We create",
      text2: "beautiful ",
      text3: "websites",
      text4: "Contact us",
    },
    german: {
      text1: "Wir kreieren",
      text2: "stilvolle ",
      text3: "Webseiten",
      text4: "Kontaktieren Sie uns",
    },
  };

  language.split("-")[0] === "de"
    ? (homeContent = homeContent.german)
    : (homeContent = homeContent.english);

  return (
    <div className="Home-Container" id="home">
      <div className={Scroll <= window.innerHeight ? "sky" : "sky none"}></div>
      <div
        className={
          Scroll <= window.innerHeight ? "constellation" : "constellation none"
        }
      ></div>
      <motion.div
        className="Home-Subcontainer"
        variants={homeVariants}
        initial={window.innerWidth <= 768 ? "visible" : "hidden"}
        animate={"visible"}
      >
        <h1 className="Home-Title">
          {homeContent.text1}{" "}
          <span style={{ color: "#dd46e1" }}>{homeContent.text2}</span>
          {homeContent.text3}
        </h1>
        <Link
          scroll={(el) => scrollWithOffset(el, 200)}
          to="/#contact"
          className="Home-Button"
        >
          {homeContent.text4}
        </Link>
      </motion.div>
    </div>
  );
}
