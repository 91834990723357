import React, { useState, useEffect } from "react";
import "../styles/Process.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Process({ language }) {
  let [Drawer, setDrawer] = useState([]);
  const { ref, inView } = useInView({ threshold: 0.2 });
  const processAnimation = useAnimation();

  let handleclick = (event) => {
    let list = Drawer;
    let num = parseInt(event.target.getAttribute("data"));
    if (list.includes(num)) {
      for (let e of list) {
        if (e === num) {
          setDrawer(list.filter((number) => e !== number));
        }
      }
    } else {
      list = [...Drawer, num];
      setDrawer(list);
    }
  };

  let processContent = {
    english: {
      title: "Our process",
      title1: "Free price inquiry ",
      text1:
        "After we have been contacted, we will send a questionnaire via email to estimate a price depending on the requirements. Further communication will be required in order to understand the full scope of work.",
      title2: "Draft",
      text2:
        "We will create a design draft with respect to the customers’ wishes that needs to be approved in order to proceed to the next step.",
      title3: "Implementation and developing",
      text3:
        "Once our customer is content with the final design, we commence the realization of the project. We make sure that it is a responsive web design so that the website can adapt to different screen sizes.",
      title4: "Hosting and maintenance",
      text4:
        "If the hosting service has been selected, then the website will now be put online. Thanks to our high-quality servers, we can guarantee that your website will be represented carefully and safely on the internet.",
    },
    german: {
      title: "Unser Prozess",
      title1: "Kostenlose Preisanfrage",
      text1:
        "Nachdem Sie mit uns in Kontakt getreten sind, senden wir Ihnen ein Formular per Email, welches Sie ausfüllen. Sodass wir abhängig von Ihren Angaben Ihnen einen Preis anbieten können.",
      title2: "Entwurf",
      text2:
        "Wir entwerfen das Design Ihrer Webseite mit Rücksicht auf das von Ihnen ausgefüllte Formular.",
      title3: "Programmierung und Umsetzung",
      text3:
        "Sobald Sie mit dem Aufbau und Design zufrieden sind, fangen wir mit der Umsetzung der Webseite an. Hierbei achten wir stets auf ein responsives Webdesign. Dies bedeutet, dass Ihre Internetseite je nach Bildschirmgröße passend dargestellt wird. Das sorgt für eine bessere Besucherfreundlichkeit.",
      title4: "Hosting und Wartung",
      text4:
        "Falls Sie sich für unseren Hosting Service entschieden haben, stellen wir nun Ihre Webseite online. Anhand unserer qualitativen Server garantieren wir Ihnen, dass Ihre Webseite sicher im Internet vertreten wird.",
    },
  };

  language.split("-")[0] === "de"
    ? (processContent = processContent.german)
    : (processContent = processContent.english);

  let Bubble = (props) => {
    return (
      <div
        className="Step-Container"
        style={props.noMargin ? { marginTop: 0 } : {}}
        id={"num" + props.num}
      >
        <div className="Bubble-Container">
          <div className="Bubble">
            <h1 className="Bubble-Number">{props.num}</h1>
          </div>
        </div>
        <div className="Step-Title-Container">
          <div
            className={
              Drawer.includes(props.num)
                ? "Drawer-Button-Active"
                : "Drawer-Button"
            }
            data={props.num}
            onClick={handleclick}
          >
            {">"}
          </div>
          <div>
            <h2 className="Step-Title" data={props.num} onClick={handleclick}>
              {props.title}
            </h2>
            <p
              className={`Step-Text ${
                Drawer.includes(props.num) ? "" : "none"
              }`}
            >
              {props.text}
            </p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (inView) {
      processAnimation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease-in-out",
          duration: 0.7,
        },
      });
    }
  }, [inView, processAnimation]);

  return (
    <div className="Process-Container" id="process">
      <h1 className="Process-Title">{processContent.title}</h1>
      <hr />
      <motion.div
        className="Steps-Container"
        ref={ref}
        initial={{ y: "6rem", opacity: 0 }}
        animate={processAnimation}
      >
        <div className="Steps-Deco">
          <div className="Steps">
            <Bubble
              num={1}
              title={processContent.title1}
              noMargin
              text={processContent.text1}
            />
            <Bubble
              num={2}
              title={processContent.title2}
              text={processContent.text2}
            />
            <Bubble
              num={3}
              title={processContent.title3}
              text={processContent.text3}
            />
            <Bubble
              num={4}
              title={processContent.title4}
              text={processContent.text4}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
