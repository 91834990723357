import React, { useState, useEffect } from "react";
import Logo from "../images/Logo.svg";
import Menu from "../images/Hamburger.svg";
import "../styles/Nav.css";
import { HashLink as Link } from "react-router-hash-link";
import Cookie from "../images/Cookie.svg";
import { motion } from "framer-motion";

export default function Nav(props) {
  const [drawer, setDrawer] = useState(false);
  const [cookieDrawer, setCookieDrawer] = useState(false);
  const [acceptCookie, setAcceptCookie] = useState(false);
  let [Width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let WidthChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", WidthChange);
  }, [Width]);

  const showDrawer = () => {
    setDrawer(!drawer);
  };

  const showCookieDrawer = () => {
    setCookieDrawer(!cookieDrawer);
  };

  const invisibleCookie = () => {
    setAcceptCookie(true);
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    if (Width <= 768) {
      window.scroll({
        top: elementPosition,
        left: 0,
      });
    } else {
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const navVariants = {
    hidden: {
      y: "-1rem",
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "ease-in-out",
        duration: 1,
        delay: 2.5,
      },
    },
  };

  let navContent = {
    english: {
      title1: "Home",
      title2: "Our services",
      title3: "Why us",
      title4: "Our process ",
      title5: "Contact",
      text1: "We use cookies.",
      text2: "Accept",
    },
    german: {
      title1: "Startseite",
      title2: "Dienstleistungen",
      title3: "Vorteile",
      title4: "Prozess",
      title5: "Kontakt",
      text1: "Wir verwenden Cookies.",
      text2: "Akzeptieren",
    },
  };

  props.language.split("-")[0] === "de"
    ? (navContent = navContent.german)
    : (navContent = navContent.english);

  return (
    <>
      <motion.nav
        variants={navVariants}
        initial={
          window.innerWidth <= 768 ? "visible" : props.animate ? "hidden" : ""
        }
        animate={"visible"}
      >
        <Link smooth={!(Width <= 768)} to="/#home" id="Nav-Logo-Link">
          <img src={Logo} alt="Company Logo" className="Nav-Logo" />
        </Link>
        <img
          src={Menu}
          alt="Menu Logo"
          className="Menu-Logo"
          onClick={showDrawer}
        />
        <div className="Desktop-Nav">
          <Link smooth to="/#home" style={{ marginLeft: "0rem" }}>
            {navContent.title1}
          </Link>
          <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#pitch">
            {navContent.title2}
          </Link>
          <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#benefits">
            {navContent.title3}
          </Link>
          <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#process">
            {navContent.title4}
          </Link>
          <Link scroll={(el) => scrollWithOffset(el, 100)} to="/#contact">
            {navContent.title5}
          </Link>
        </div>
      </motion.nav>

      {/* Mobile version */}

      <div className={drawer ? "Drawer active" : "Drawer"} onClick={showDrawer}>
        <div className="Drawer-Navigation">
          <li onClick={showDrawer}>
            <Link to="/#home">{navContent.title1}</Link>
          </li>
          <li onClick={showDrawer}>
            <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#pitch">
              {navContent.title2}
            </Link>
          </li>
          <li onClick={showDrawer}>
            <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#benefits">
              {navContent.title3}
            </Link>
          </li>
          <li onClick={showDrawer}>
            <Link scroll={(el) => scrollWithOffset(el, 200)} to="/#process">
              {navContent.title4}
            </Link>
          </li>
          <li onClick={showDrawer}>
            <Link to="/#contact">{navContent.title5}</Link>
          </li>
        </div>
      </div>
      <div
        className={cookieDrawer ? "Cookie active" : "Cookie"}
        onClick={showCookieDrawer}
        id={acceptCookie ? "Cookie-Invisible" : ""}
      >
        <img src={Cookie} alt="Cookie Icon" className="Cookie-Icon" />
        <div className="Cookie-Content">
          <h3 className="Cookie-Title">{navContent.text1}</h3>
          <button onClick={invisibleCookie}>{navContent.text2}</button>
        </div>
      </div>
    </>
  );
}
