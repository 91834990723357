import React, { useEffect, useState } from "react";
import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";
import "../styles/Benefits.css";
import Chart from "../images/Chart.svg";
import Diamond from "../images/Diamond.svg";
import Handshake from "../images/Handshake.svg";
import UdChart from "../images/Undraw-Chart.svg";
import UdQuality from "../images/Undraw-Quality.svg";
import UdBusiness from "../images/Undraw-Business.svg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const createStyles = (isActive) => ({
  transition: "0.3s linear",
  background: "transparent",
  border: 0,
  color: isActive ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, 0.2)",
  fontSize: "3rem",
  margin: "0 20px",
});

export default function Benefits({ language }) {
  const [actualSlide, setActualSlide] = useState(0);
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView({ threshold: 0.2 });
  const benefitFirstContainerAnimation = useAnimation();
  const benefitSecondContainerAnimation = useAnimation();
  const benefitThirdContainerAnimation = useAnimation();
  const benefitFourthContainerAnimation = useAnimation();

  useEffect(() => {
    if (inView1) {
      benefitFirstContainerAnimation.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.7,
          type: "ease-in-out",
        },
      });
    }
  }, [inView1, benefitFirstContainerAnimation]);

  useEffect(() => {
    if (inView2) {
      benefitSecondContainerAnimation.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.7,
          type: "ease-in-out",
        },
      });
    }
  }, [inView2, benefitSecondContainerAnimation]);

  useEffect(() => {
    if (inView3) {
      benefitThirdContainerAnimation.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.7,
          type: "ease-in-out",
        },
      });
    }
  }, [inView3, benefitThirdContainerAnimation]);

  useEffect(() => {
    if (inView4) {
      benefitFourthContainerAnimation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease-in-out",
          duration: 0.7,
        },
      });
    }
  }, [inView4, benefitFourthContainerAnimation]);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };

  const indicator = [1, 2, 3];

  let benefitsContent = {
    english: {
      title: "Why us?",
      title1: "More profit and increased reach",
      text1:
        "A website is the representative of your company in the ever-increasing online world. If it is plain or unsightly, then it will leave a bad impression on your customers. As a consequence, this will influence the brand image of your company negatively. Our mission is it to prevent this case. Therefore, we strive to create beautiful and modern websites for our clients.  Additionally, our websites are SEO optimized which will enhance the ranking of your company in search engines such as Google, Yahoo etc. And thus, providing more reach.",
      title2: "Quality in every aspect",
      text2:
        "We tailor each of our websites according to our customers’ needs, target group and the industry they are in. We achieve this result by continuous and seamless communication with our clients throughout the whole draft process. As such, this allows us to deliver visually striking websites that stand out from the crowd. The implementation and programming process will be executed by our competent developers efficiently. If you would also like to benefit from our additional hosting service, you can leave the rest to us. We will make sure your website will be cared for.",
      title3: "Excellent customer service at every stage",
      text3:
        "Even after successfully delivering a project, we will be there for our customers. If you need help with anything, we would gladly assist in any way possible. Beyond that, if you also sign up for the additional hosting service, you also benefit from a user-friendly and personalized interface, which enables you to freely edit the text or the pictures within your website – without any programming skills.",
    },
    german: {
      title: "Ihre Vorteile bei uns",
      title1: "Mehr Umsatz und höhere Reichweite",
      text1:
        "Eine Webseite ist das Gesicht Ihres Unternehmens im Internet. Falls diese veraltet und unansehnlich ist, dann hinterlässt es einen schlechten Eindruck bei Ihren Kunden. Als Folge wird Ihr Image negativ beeinflusst. Unser Job ist es, dieses Szenario zu verhindern. Deshalb erstellen wir moderne und schöne Webseiten für Sie. Zusätzlich sind unsere Webseiten SEO optimiert, daher kann Ihr Unternehmen schneller und besser in Suchmaschinen wie Google, Yahoo etc. entdeckt und gefunden werden. Ihre Reichweite steigt dadurch erheblich, was zu einem erhöhten Umsatz führen kann.",
      title2: "Qualität in jeder Hinsicht",
      text2:
        "Jeder unserer Webseiten passen wir auf Ihre Bedürfnisse, Zielgruppe und Industrie maßgeschnitten an. Dies erreichen wir durch eine stetige und gute Kommunikation mit Ihnen während der Entwurfsphase. Mithilfe diesem Prozess sticht Ihre Webseite aus der Masse visuell heraus, was Ihnen optimale Ergebnisse liefert. Auch die Umsetzung und Programmierung erfolgt mittels unseren kompetenten Entwicklern effizient und schnell. Falls Sie sich darüber hinaus für unseren Hosting Service entscheiden, können Sie dank unserem robusten Sicherheitssystems den Rest sorglos an uns überlassen.",
      title3: "Guter Service, auch nach dem Erwerb",
      text3:
        "Auch nach Vollendung und Übergabe der Webseite sind wir bei Anliegen immer für Sie da. Zusätzlich nach dem Erwerb unseres Hosting Services passen wir des weiteren kostenfrei auf, dass Ihre Webseite reibungslos ohne Probleme funktioniert. Darüber hinaus profitieren Sie von einem benutzerfreundlichem und personalisiertem Interface, welches Ihnen ermöglicht Bilder und Texte innerhalb Ihrer Webseite eigenständig je nach Belieben zu bearbeiten - auch ohne jegliche Programmierkenntnisse.",
    },
  };

  let meta = {
    image1: Chart,
    alt1: "More reach and profits",
    id1: "Chart",
    image2: Diamond,
    alt2: "Good quality",
    id2: "Diamond",
    image3: Handshake,
    alt3: "Good service",
    id3: "Handshake",
  };

  console.log(meta.alt1);

  language.split("-")[0] === "de"
    ? (benefitsContent = benefitsContent.german)
    : (benefitsContent = benefitsContent.english);

  return (
    <div className="Benefits-Container">
      <h1 className="Benefits-Title" id="benefits">
        {benefitsContent.title}
      </h1>
      <hr />
      <div className="Benefits-Large-Container">
        <motion.div
          className="Benefit-Container"
          id="Benefit1"
          ref={ref1}
          initial={{ x: "15vw", opacity: 0 }}
          animate={benefitFirstContainerAnimation}
        >
          <img src={UdChart} id="UdChart" alt="Chart Background" />
          <h2 className="Benefit-Title Right">{benefitsContent.title1}</h2>
          <p className="Benefit-Text Right">{benefitsContent.text1}</p>
        </motion.div>
        <motion.div
          className="Benefit-Container"
          id="Benefit2"
          ref={ref2}
          initial={{ x: "-15vw", opacity: 0 }}
          animate={benefitSecondContainerAnimation}
        >
          <img src={UdQuality} id="UdQuality" alt="Quality Background" />
          <h2 className="Benefit-Title Left">{benefitsContent.title2}</h2>
          <p className="Benefit-Text Left">{benefitsContent.text2}</p>
        </motion.div>
        <motion.div
          className="Benefit-Container"
          id="Benefit3"
          ref={ref3}
          initial={{ x: "15vw", opacity: 0 }}
          animate={benefitThirdContainerAnimation}
        >
          <img src={UdBusiness} id="UdBusiness" alt="Business Background" />
          <h2 className="Benefit-Title Right">{benefitsContent.title3}</h2>
          <p className="Benefit-Text Right">{benefitsContent.text3}</p>
        </motion.div>
      </div>
      <motion.div
        className="Benefits-Slider"
        ref={ref4}
        initial={{ y: "6rem", opacity: 0 }}
        animate={benefitFourthContainerAnimation}
      >
        <ReactSlidy
          keyboardNavigation={true}
          slideSpeed={100}
          doAfterSlide={updateSlide}
          slide={actualSlide}
          showArrows={false}
        >
          <div className="Benefits-Subcontainer">
            <img
              className="Benefits-Image"
              alt={meta.alt1}
              src={meta.image1}
              style={{ height: "13rem" }}
              id={meta.id1}
            />
            <h2 className="Benefits-Subtitle">{benefitsContent.title1}</h2>
            <p className="Benefits-Text">{benefitsContent.text1}</p>
          </div>

          <div className="Benefits-Subcontainer">
            <img
              className="Benefits-Image"
              alt={meta.alt2}
              src={meta.image2}
              style={{ height: "13rem" }}
              id={meta.id2}
            />
            <h2 className="Benefits-Subtitle">{benefitsContent.title2}</h2>
            <p className="Benefits-Text">{benefitsContent.text2}</p>
          </div>

          <div className="Benefits-Subcontainer">
            <img
              className="Benefits-Image"
              alt={meta.alt3}
              src={meta.image3}
              style={{ height: "13rem" }}
              id={meta.id3}
            />
            <h2 className="Benefits-Subtitle">{benefitsContent.title3}</h2>
            <p className="Benefits-Text">{benefitsContent.text3}</p>
          </div>
        </ReactSlidy>
        <div className="Dots">
          {indicator.map((_, index) => {
            return (
              <button
                key={index}
                style={createStyles(index === actualSlide)}
                onClick={() => updateSlide({ currentSlide: index })}
              >
                &bull;
              </button>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
}
