import React from "react";
import Navbar from "./Nav";
import Footer from "./Footer";
import "../styles/Legal.css";

export default React.memo(function LegalNotice({ language }) {
  window.scrollTo(0, 0);

  let legalNoticeContent = {
    english: {
      title: "Legal Notice",
      text0: "Germany",
      text: "Laven Webdesign & -development",
      text1: "Telephone: +49 177 7535532",
      text2:
        "The EU-commission has created an online-platform to resolve any disputes. The platform serves as a contact point for the extrajudicial solving of problems about contractual duties, which resulted from any online purchase contract. Additional information can be found at: http://ec.europa.eu/consumers/odr. For the participation of a dispute resolution procedure before a consumer arbitration board, we are neither ready nor responsible.",
      text3:
        "Due to the small business status acc. § 19 UStG we do not levy any VAT and as such, do reveal them either.",
    },
    german: {
      title: "Impressum",
      text0: "Deutschland",
      text: "Laven Webdesign & -entwicklung",
      text1: "Telefon: +49 177 7535532",
      text2:
        "Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen. Nähere Informationen sind unter dem folgenden Link verfügbar: http://ec.europa.eu/consumers/odr. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir weder bereit noch verpflichtet.",
      text3:
        "Aufgrund des Kleinunternehmerstatus gem. § 19 UStG erheben wir keine Umsatzsteuer und weisen diese daher auch nicht aus.",
    },
  };

  language.split("-")[0] === "de"
    ? (legalNoticeContent = legalNoticeContent.german)
    : (legalNoticeContent = legalNoticeContent.english);

  return (
    <>
      <div className="Legal-Container">
        <Navbar language={language} />
        <h1>{legalNoticeContent.title}</h1>
        <p className="Legal-Notice">
          Nevzat Cesur
          <br /> {legalNoticeContent.text}
          <br /> Am Margaretenbrünnele 19
          <br /> 86470 Thannhausen
          <br /> {legalNoticeContent.text0}
          <br /> {legalNoticeContent.text1}
          <br /> E-Mail: laven.web@gmail.com
          <span className="Legal-Notice-Text">{legalNoticeContent.text2}</span>
          <span style={{ marginTop: "4rem", display: "block" }}>
            {legalNoticeContent.text3}
          </span>
        </p>
      </div>
      <Footer inverted language={language} />
    </>
  );
});
