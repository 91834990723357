import React, { useEffect } from "react";
import "../styles/Pitch.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Pitch({ language }) {
  const { ref, inView } = useInView({ threshold: 0.5 });
  const pitchAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      pitchAnimation.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "ease-in-out",
          duration: 0.7,
        },
      });
    }
  }, [inView, pitchAnimation]);

  let pitchContent = {
    english: {
      title: "Our services",
      text: "We are a young company whose goal is it to establish or improve the online presence of other companies with the help of our skills in Web design and -development. Additionally, our clients have the choice to make use of our hosting and maintenance offers.",
    },
    german: {
      title: "Unsere Dienstleistungen",
      text: "Wir sind ein junges Unternehmen, welches sich das Ziel gesetzt hat, die Online Präsenz anderer Unternehmen anhand unserer Fähigkeiten und Kenntnisse in Design und Development zu digitalisieren und modernisieren. Unser Fokus liegt dabei bei dem Entwerfen und Entwickeln von Webseiten. Außerdem können Sie von unserem zusätzlichen Hosting - und Verwaltungsservice profitieren, falls Sie Ihre Webseite von uns fertigen lassen.",
    },
  };

  language.split("-")[0] === "de"
    ? (pitchContent = pitchContent.german)
    : (pitchContent = pitchContent.english);

  return (
    <div ref={ref} className="Pitch-Container">
      <h1 className="Pitch-Title" id="pitch">
        {pitchContent.title}
      </h1>
      <hr />
      <motion.div initial={{ y: "6rem", opacity: 0 }} animate={pitchAnimation}>
        <p className="Pitch-Text">{pitchContent.text}</p>
      </motion.div>
    </div>
  );
}
